import axios from 'axios'

export default {
  purchasedButNoServiceRecords (data) {
    return axios.get(
      '/dms/reporting/purchased_but_no_service_records?start_date=' + data.startDate + '&end_date=' + data.endDate + '&apr=' + data.apr + '&apr_operator=' + data.aprOperator + '&payment=' + data.payment +
      '&payment_operator=' + data.paymentOperator + '&months_remaining=' + data.paymentsRemaining + '&months_remaining_operator=' + data.paymentsRemainingOperator +
      '&months_since_sale=' + data.monthsSinceSale + '&months_since_sale_operator=' + data.monthsSinceSaleOperator +
      '&term_complete_percent=' + data.termCompletePercent + '&term_complete_percent_operator=' + data.termCompletePercentOperator +
      '&make=' + data.makes + '&model=' + data.models + '&year=' + data.years + '&states=' + data.states + '&conditions=' + data.conditions +
      '&header_text=' + data.headerText + '&header_values=' + data.headerValues +
      '&campaign_id=' + data.campaignId +
      '&description=' + data.fileDescription,
    )
  },
  notRecentlyServiced (data) {
    return axios.get(
      '/dms/reporting/not_recently_serviced?start_date=' + data.startDate + '&end_date=' + data.endDate + '&days_since_last_serviced=' + data.daysSinceLastServiced +
      '&campaign_id=' + data.campaignId + '&header_values=' + data.headerValues +
      '&description=' + data.fileDescription + '&makes=' + data.makes,
    )
  },
  salesQuery (data) {
    return axios.get(
      '/dms/reporting/sales_query?start_date=' + data.startDate + '&end_date=' + data.endDate + '&apr=' + data.apr + '&apr_operator=' + data.aprOperator + '&payment=' + data.payment +
      '&payment_operator=' + data.paymentOperator + '&months_remaining=' + data.paymentsRemaining + '&months_remaining_operator=' + data.paymentsRemainingOperator +
      '&months_since_sale=' + data.monthsSinceSale + '&months_since_sale_operator=' + data.monthsSinceSaleOperator +
      '&term_complete_percent=' + data.termCompletePercent + '&term_complete_percent_operator=' + data.termCompletePercentOperator +
      '&make=' + data.makes + '&model=' + data.models + '&year=' + data.years + '&states=' + data.states + '&conditions=' + data.conditions +
      '&header_text=' + data.headerText + '&header_values=' + data.headerValues +
      '&campaign_id=' + data.campaignId +
      '&description=' + data.fileDescription + 
      '&no_vsc=' + data.noVsc,
    )
  },
  serviceQuery (data) {
    return axios.get(
      '/dms/reporting/service_query?start_date=' + data.startDate + '&end_date=' + data.endDate +
      '&make=' + data.makes + '&model=' + data.models + '&year=' + data.years + '&states=' + data.states +
      '&start_last_serviced_date=' + data.startLastServicedDate +
      '&end_last_serviced_date=' + data.endLastServiceDate +
      '&header_text=' + data.headerText + '&header_values=' + data.headerValues +
      '&campaign_id=' + data.campaignId +
      '&op_codes=' + data.opCodes + 
      '&description=' + data.fileDescription,
    )
  },
  retrieveReport (cacheKey, campaignId, description, headers, reportType, fileName) {
    return axios.get(
      '/dms/reporting/retrieve_report?cache_key=' + cacheKey + '&campaign_id=' + campaignId + '&description=' + description + '&headers=' + headers + '&report_type=' + reportType + '&filename=' + fileName,
    )
  },
  emailReport (data) {
    return axios.get(
      '/dms/reporting/email_report?cache_key=' + data.cacheKey + '&header_values=' + data.headerValues,
    )
  },
  searchOpCodes (data) {
    return axios.get(
      'chatovate/op_codes?term=' + data.term,
    )
  },
}
