<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-row>
        <v-col cols="12">
          <template>
            <v-stepper v-model="wizard">
              <v-stepper-header>
                <v-stepper-step
                  :complete="wizard > 1"
                  step="1"
                >
                  Select Mission Type and Date Range
                </v-stepper-step>

                <v-divider />
                <v-stepper-step
                  :complete="wizard > 2"
                  step="2"
                >
                  Select Finance Details
                  <template v-if="this.missionType === 'Service'">
                    <small>Only for Sales Missions</small>
                  </template>                    
                </v-stepper-step>
                <v-divider />
                <v-stepper-step
                  :complete="wizard > 3"
                  step="3"
                >
                  Select Year, Make, Model, State
                </v-stepper-step>

                <v-divider />
                <v-stepper-step
                  :complete="wizard > 4"
                  step="4"
                >
                  Mission Settings
                </v-stepper-step>

                <v-divider />
              </v-stepper-header>

              <v-stepper-items>
                <template v-if="(!loading && missionType === 'Sales' && !callTrackingNumber) || (!loading && missionType === 'Service' && !serviceCallTrackingNumber)">
                  <v-alert
                    prominent
                    dense
                    type="error"
                  >
                    <v-row align="center">
                      <v-col class="grsow">
                        You have not set up a call tracking number for {{ missionType}} missions. Click the Settings button to set that now.
                      </v-col>
                      <v-col class="shrink">
                        <v-btn
                          @click="navigateToSettings()"
                        >
                          Settings
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-alert>
                </template>
                <v-stepper-content step="1">
                  <v-card
                    color="lighten-1"
                  >
                  <v-container fluid>
                    <v-radio-group v-model="missionType">
                      <template v-slot:label>
                        <div>Which type of customers do you want to reach with this mission?</div>
                      </template>
                      <v-radio value="Sales">
                        <template v-slot:label>
                          <div>I want to reach my <strong class="primary--text">Sales</strong> customers</div>
                        </template>
                      </v-radio>
                      <v-radio value="Service">
                        <template v-slot:label>
                          <div>I want to reach my <strong class="secondary--text">Service</strong> customers</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </v-container>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="useAllDatesSwitch"
                          class="pl-2"
                          inset
                          :label="`Search all ${missionType} records: ${useAllDatesSwitch ? 'Yes' : 'No'}`"
                        />
                      </v-col>
                    </v-row>
                    <template v-if="!useAllDatesSwitch">
                      <v-col>
                        <v-alert
                          icon="mdi-information"
                          border="top"
                          dense
                          color="blue"
                          elevation="6"
                          :text="true"
                        >
                          Select the date ranges you wish to search <strong>below</strong>
                        </v-alert>
                      </v-col>
                      <v-row>
                        <v-col
                          class="xs"
                          sm="6"
                          md="4"
                          lg="2"
                          xl="1"
                        >
                          <v-menu
                            v-model="startDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="formattedStartDate"
                                label="Start Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="startDate"
                              @input="startDateMenu = false"
                            />
                          </v-menu>
                        </v-col>
                        <v-col
                          class="xs"
                          sm="6"
                          md="4"
                          lg="2"
                          xl="1"
                        >
                          <v-menu
                            v-model="endDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="formattedEndDate"
                                label="End Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="endDate"
                              @input="endDateMenu = false"
                            />
                          </v-menu>
                        </v-col>
                      </v-row>
                    </template>
                  </v-card>

                  <v-btn
                    color="primary"
                    @click="handleStepOneClick()"
                  >
                    Continue
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <v-card
                    color="lighten-1"
                  >
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="filterByAprSwitch"
                          class="pl-2"
                          inset
                          :label="formatFilterMessage('APR', filterByAprSwitch, aprFilterOperatorValue, aprFilterValue)"
                        />
                      </v-col>
                      <v-dialog
                        v-model="showAprDialog"
                        persistent
                        max-width="500"
                      >
                        <v-card class="pl-2 pr-2">
                          <v-card-title class="headline">
                            Filter by APR
                          </v-card-title>
                          <v-row>
                            <v-col>
                              <v-list shaped>
                                <v-subheader>SELECT FILTER TYPE</v-subheader>
                                <v-list-item-group
                                  v-model="aprFilterOperatorValue"
                                  color="primary"
                                >
                                  <v-list-item
                                    v-for="(item, i) in filterOperators"
                                    :key="i"
                                  >
                                    <v-list-item-icon>
                                      <v-icon v-text="item.icon" />
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title v-text="item.text" />
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-col>
                            <v-col class="d-flex align-center">
                              <v-text-field
                                v-model="aprFilterValue"
                                label="APR"
                                outlined
                              />
                            </v-col>
                          </v-row>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="green darken-1"
                              text
                              @click="showAprDialog = false"
                            >
                              Done
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-col>
                        <v-switch
                          v-model="filterByPayment"
                          class="pl-2"
                          inset
                          :label="formatFilterMessage('Payment Amount', filterByPayment, paymentFilterOperatorValue, paymentFilterValue)"
                        />
                      </v-col>
                      <v-dialog
                        v-model="showPaymentDialog"
                        persistent
                        max-width="500"
                      >
                        <v-card class="pl-2 pr-2">
                          <v-card-title class="headline">
                            Filter by Monthly Payment
                          </v-card-title>
                          <v-row>
                            <v-col>
                              <v-list shaped>
                                <v-subheader>SELECT FILTER TYPE</v-subheader>
                                <v-list-item-group
                                  v-model="paymentFilterOperatorValue"
                                  color="primary"
                                >
                                  <v-list-item
                                    v-for="(item, i) in filterOperators"
                                    :key="i"
                                  >
                                    <v-list-item-icon>
                                      <v-icon v-text="item.icon" />
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title v-text="item.text" />
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-col>
                            <v-col class="d-flex align-center">
                              <v-text-field
                                v-model="paymentFilterValue"
                                label="Monthly Payment"
                                outlined
                              />
                            </v-col>
                          </v-row>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="green darken-1"
                              text
                              @click="showPaymentDialog = false"
                            >
                              Done
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-col>
                        <v-switch
                          v-model="filterByPaymentsRemaining"
                          class="pl-2"
                          inset
                          :label="formatFilterMessage('Payments Remaining', filterByPaymentsRemaining, paymentRemainingFilterOperatorValue, paymentsRemainingFilterValue)"
                        />
                      </v-col>
                      <v-dialog
                        v-model="showPaymentsRemainingDialog"
                        persistent
                        max-width="500"
                      >
                        <v-card class="pl-2 pr-2">
                          <v-card-title class="headline">
                            Filter by Payments Remaining
                          </v-card-title>
                          <v-row>
                            <v-col>
                              <v-list shaped>
                                <v-subheader>SELECT FILTER TYPE</v-subheader>
                                <v-list-item-group
                                  v-model="paymentRemainingFilterOperatorValue"
                                  color="primary"
                                >
                                  <v-list-item
                                    v-for="(item, i) in filterOperators"
                                    :key="i"
                                  >
                                    <v-list-item-icon>
                                      <v-icon v-text="item.icon" />
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title v-text="item.text" />
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-col>
                            <v-col class="d-flex align-center">
                              <v-text-field
                                v-model="paymentsRemainingFilterValue"
                                label="Payments Remaining"
                                outlined
                              />
                            </v-col>
                          </v-row>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="green darken-1"
                              text
                              @click="showPaymentsRemainingDialog = false"
                            >
                              Done
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-col>
                        <v-switch
                          v-model="filterByMonthsSinceSale"
                          class="pl-2"
                          inset
                          :label="formatFilterMessage('Months Since Sale', filterByMonthsSinceSale, monthsSinceSaleFilterOperatorValue, monthsSinceSaleFilterValue)"
                        />
                      </v-col>
                      <v-dialog
                        v-model="showMonthsSinceSaleDialog"
                        persistent
                        max-width="500"
                      >
                        <v-card class="pl-2 pr-2">
                          <v-card-title class="headline">
                            Filter by Months Since Sale
                          </v-card-title>
                          <v-row>
                            <v-col>
                              <v-list shaped>
                                <v-subheader>SELECT FILTER TYPE</v-subheader>
                                <v-list-item-group
                                  v-model="monthsSinceSaleFilterOperatorValue"
                                  color="primary"
                                >
                                  <v-list-item
                                    v-for="(item, i) in filterOperators"
                                    :key="i"
                                  >
                                    <v-list-item-icon>
                                      <v-icon v-text="item.icon" />
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title v-text="item.text" />
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-col>
                            <v-col class="d-flex align-center">
                              <v-text-field
                                v-model="monthsSinceSaleFilterValue"
                                label="Months Since Sale"
                                outlined
                              />
                            </v-col>
                          </v-row>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="green darken-1"
                              text
                              @click="showMonthsSinceSaleDialog = false"
                            >
                              Done
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-col>
                        <v-switch
                          v-model="filterByTermPercentComplete"
                          class="pl-2"
                          inset
                          :label="formatFilterMessage('% Term Complete', filterByTermPercentComplete, termPercentFilterOperatorValue, termPercentFilterValue)"
                        />
                      </v-col>
                      <v-dialog
                        v-model="showTermPercentDialog"
                        persistent
                        max-width="500"
                      >
                        <v-card class="pl-2 pr-2">
                          <v-card-title class="headline">
                            Filter by % Term Complete
                          </v-card-title>
                          <v-row>
                            <v-col>
                              <v-list shaped>
                                <v-subheader>SELECT FILTER TYPE</v-subheader>
                                <v-list-item-group
                                  v-model="termPercentFilterOperatorValue"
                                  color="primary"
                                >
                                  <v-list-item
                                    v-for="(item, i) in filterOperators"
                                    :key="i"
                                  >
                                    <v-list-item-icon>
                                      <v-icon v-text="item.icon" />
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title v-text="item.text" />
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-col>
                            <v-col class="d-flex align-center">
                              <v-text-field
                                v-model="termPercentFilterValue"
                                label="% Term Complete"
                                outlined
                              />
                            </v-col>
                          </v-row>
                          <v-card-actions>
                            <v-spacer />
                            <v-btn
                              color="green darken-1"
                              text
                              @click="showTermPercentDialog = false"
                            >
                              Done
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="loadStep(3);"
                  >
                    Continue
                  </v-btn>
                  <v-btn
                    text
                    @click="loadStep(1);"
                  >
                    Back
                  </v-btn>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <v-card
                    color="lighten-1"
                  >
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="filterByYearsSwitch"
                          class="pl-2"
                          inset
                          :label="'Filter by Year? ' + formatBoolean (filterByYearsSwitch)"
                        />
                      </v-col>
                      <template v-if="filterByYearsSwitch">
                        <v-col>
                          <v-combobox
                            v-model="selectedYears"
                            :items="allYears"
                            label="Select the years you want to filter by"
                            multiple
                          />
                        </v-col>
                      </template>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="filterByMakesSwitch"
                          class="pl-2"
                          inset
                          :label="'Filter by Make? ' + formatBoolean (filterByMakesSwitch)"
                        />
                      </v-col>
                      <template v-if="filterByMakesSwitch">
                        <v-col>
                          <v-combobox
                            v-model="selectedMakes"
                            :items="allMakes"
                            label="Select the makes you want to filter by"
                            multiple
                          />
                        </v-col>
                      </template>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="filterByModelsSwitch"
                          class="pl-2"
                          inset
                          :label="'Filter by Model? ' + formatBoolean (filterByModelsSwitch)"
                        />
                      </v-col>
                      <template v-if="filterByModelsSwitch">
                        <v-col>
                          <v-combobox
                            v-model="selectedModels"
                            :items="allModels"
                            label="Select the models you want to filter by"
                            multiple
                          />
                        </v-col>
                      </template>
                    </v-row>
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="filterByStatesSwitch"
                          class="pl-2"
                          inset
                          :label="'Filter by State? ' + formatBoolean (filterByStatesSwitch)"
                        />
                      </v-col>
                      <template v-if="filterByStatesSwitch">
                        <v-col>
                          <v-combobox
                            v-model="selectedStates"
                            :items="allStates"
                            label="Select the states you want to filter by"
                            multiple
                          />
                        </v-col>
                      </template>
                    </v-row>
                  <template v-if="missionType === 'Service'">
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="filterByLastServicedSwitch"
                          class="pl-2"
                          inset
                          :label="'Filter by Last Serviced Date? ' + formatBoolean (filterByLastServicedSwitch)"
                        />
                      </v-col>
                      <template v-if="filterByLastServicedSwitch">
                        <v-col>
                          <v-menu
                            v-model="startLastServicedDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="formattedLastServiceStartDate"
                                label="Start Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="startLastServicedDate"
                              @input="startLastServicedDateMenu = false"
                            />
                          </v-menu>
                        </v-col>
                        <v-col>
                          <v-menu
                            v-model="endLastServicedDateMenu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                v-model="formattedLastServiceEndDate"
                                label="End Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-on="on"
                              />
                            </template>
                            <v-date-picker
                              v-model="endLastServicedDate"
                              @input="endLastServicedDateMenu = false"
                            />
                          </v-menu>
                        </v-col>
                      </template>
                    </v-row>  
                    <v-row>
                      <v-col>
                        <v-switch
                          v-model="filterByOpCodesSwitch"
                          class="pl-2"
                          inset
                          :label="'Filter by Op Codes? ' + formatBoolean (filterByOpCodesSwitch)"
                        />
                      </v-col>
                      <template v-if="filterByOpCodesSwitch">
                        <v-col>
                          <v-autocomplete 
                                    v-model="opCodes" 
                                    :items="entries" 
                                    :loading="isLoading" 
                                    :search-input.sync="opCodeSearch"
                                    hide-no-data 
                                    hide-selected 
                                    item-text="Description" 
                                    item-value="Op Codes" 
                                    label="Op Codes" 
                                    placeholder="Start typing to search" 
                                    prepend-icon="mdi-database-search"
                                    return-object
                                    chips
                                    deletable-chips
                                    multiple
                                    >
                          </v-autocomplete>
                        </v-col>
                      </template>
                    </v-row>      
                  </template>                 
                  </v-card>
                  <v-btn
                    color="primary"
                    @click="loadStep(4);"
                  >
                    Continue
                  </v-btn>
                  <v-btn
                    text
                    @click="handleStepThreeBack()"
                  >
                    Back
                  </v-btn>
                </v-stepper-content>
                <v-stepper-content step="4">
                  <v-card
                    color="lighten-1"
                  >
                    <v-row dense>
                      <v-col>
                        <v-text-field
                          v-model="campaignName"
                          label="Mission Name"
                          outlined
                          required
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="ma-3"
                            fab
                            dark
                            small
                            color="secondary"
                            v-bind="attrs"
                            v-on="on"
                            @click="showMessageHelpDialog()"                      
                          >
                              <v-icon dark>
                                mdi-help
                              </v-icon>
                          </v-btn>
                        </template>
                        <span>Click here to learn more about how to personalize messages your customers will receive.</span>
                      </v-tooltip>

                        <v-textarea
                          v-model="initialTextMessage"
                          label="Initial Message"
                          outlined
                          rows="4"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-textarea
                          v-model="secondTextMessage"
                          label="Second Message"
                          outlined
                          rows="4"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="daysToWaitForSecondText"
                          label="Days To Wait For Second Message"
                          outlined
                          hide-details
                          type="number"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <v-textarea
                          v-model="thirdTextMessage"
                          label="Third Message"
                          outlined
                          rows="4"
                        />
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="daysToWaitForThirdText"
                          label="Days To Wait For Third Message"
                          outlined
                          hide-details
                          type="number"
                        />
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <validation-provider
                          v-slot="{ errors }"
                          rules="required"
                          name="Validation Rule 'A user must be selected'"
                        >
                          <v-combobox
                            v-model="selectedUsers"
                            :items="users"
                            label="Select the users that you want to assign to this mission"
                            multiple
                            chips
                            item-text="email"
                            item-value="id"
                            :error-messages="errors"
                          />
                        </validation-provider>
                      </v-col>
                      <v-col>
                        <v-text-field
                          v-model="maxNewLeadsPerDay"
                          label="Maximum number of new customers to import from the DMS each day"
                          outlined
                          hide-details
                          type="number"
                        />
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-btn
                    color="primary"
                    :disabled="!valid"
                    @click="saveCampaign"
                  >
                    Launch
                    <v-icon
                      right
                      dark
                    >
                      mdi-rocket-launch-outline
                    </v-icon>
                  </v-btn>
                  <v-btn
                    text
                    @click="loadStep(3);"
                  >
                    Back
                  </v-btn>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </template>
        </v-col>
      </v-row>
    </v-form>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="#FF5E40"
        dark
      >
        <v-card-text>
          {{ loadingMessage }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>
    <v-dialog
      v-model="showMessageHelp"
      max-width="600"
      @keydown.esc="showMessageHelp = false"
    >
      <v-card class="pl-2 pr-2">
        <v-card-title class="headline">
          Template
        </v-card-title>
        <v-row dense>
          <template>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Template
                    </th>
                    <th class="text-left">
                      Description
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in templateRules"
                    :key="item.template"
                  >
                    <td>{{ item.template }}</td>
                    <td>{{ item.definition }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>          
        </v-row>

        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="showMessageHelp = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import DmsReportingService from '@/services/DmsReportingService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import DmsService from '@/services/DmsService.js'
  import UserService from '@/services/UserService.js'
  import ChatovateService from '@/services/ChatovateService.js'
  import { mapGetters } from 'vuex'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'
  import titleMixin from '@/mixins/titleMixin.js'

  export default {
    name: 'ChatovateCampaign',
    components: {
      ErrorMessage,
    },
    mixins: [formatterMixin, navigationMixin, titleMixin],
    data () {
      return {
        showMessageHelp: false,
        showErrorDialog: false,
        missionType: 'Sales',
        error: '',
        loadingMessage: 'Loading',
        startDate: null,
        endDate: null,
        cacheKey: null,
        campaignDate: new Date(new Date().setDate(new Date().getDate()))
          .toISOString()
          .substring(0, 10),
        campaignName: 'Customer Mission',
        wizard: 1,
        startDateMenu: false,
        endDateMenu: false,
        useAllDatesSwitch: true,
        filterByAprSwitch: false,
        showAprDialog: false,
        showPaymentDialog: false,
        showPaymentsRemainingDialog: false,
        showMonthsSinceSaleDialog: false,
        showTermPercentDialog: false,
        filterByPayment: false,
        filterByPaymentsRemaining: false,
        filterByMonthsSinceSale: false,
        filterByTermPercentComplete: false,
        filterByMakesSwitch: false,
        filterByModelsSwitch: false,
        filterByYearsSwitch: false,
        filterByStatesSwitch: false,
        filterByLastServicedSwitch: false,
        filterByOpCodesSwitch: false,
        search: '',
        loading: false,
        expanded: [],
        singleExpand: false,
        aprFilterOperatorValue: 1,
        aprFilterValue: 10,
        paymentFilterOperatorValue: 1,
        paymentFilterValue: 500,
        paymentRemainingFilterOperatorValue: 2,
        paymentsRemainingFilterValue: 12,
        monthsSinceSaleFilterOperatorValue: 1,
        monthsSinceSaleFilterValue: 36,
        termPercentFilterOperatorValue: 1,
        termPercentFilterValue: 80,
        allMakes: [],
        selectedMakes: [],
        allModels: [],
        selectedModels: [],
        allYears: [],
        selectedYears: [],
        allStates: [],
        selectedStates: [],
        initialTextMessage: 'Hi {customer.first_name}, this is {user.first_name} at {dealer.name}. We have a great selection of inventory currently, and we\'re paying more for trades now than ever. Would you be interested in upgrading your {year} {make} {model} that you bought from us to another vehicle? Thanks!',
        secondTextMessage: 'Hi {customer.first_name}, it\'s {user.first_name} at {dealer.name} again. I don\'t want to bother you, but would you be interested in getting an estimate on what your {year} {make} {model} is currently worth? Thanks!',
        thirdTextMessage: 'Hi {customer.first_name}, this will be the last time I reach out, but I wanted to let you know we have some great vehicles in stock. We\'d love to give you a free trade appraisal on your {year} {make} {model}. Thanks!',
        daysToWaitForSecondText: 7,
        daysToWaitForThirdText: 10,
        campaignId: null,
        maxNewLeadsPerDay: 50,
        loadingCampaign: false,
        callTrackingNumber: null,
        serviceCallTrackingNumber: null,

        rules: {
          required: value => !!value || 'Required.',
        },
        filterOperators: [
          { text: 'Equals', icon: 'mdi-equal', operator: '=' },
          { text: 'Greater Than', icon: 'mdi-greater-than', operator: '>' },
          { text: 'Less Than', icon: 'mdi-less-than', operator: '<' },
        ],
        users: [],
        selectedUsers: [],
        valid: true,
        selectedHeaders: [
          { text: 'Customer Number', value: 'customer_number', align: 'center' },
          { text: 'Deal Number', value: 'deal_number' },
          { text: 'Sold Date', value: 'sold_at' },
          { text: 'Name', value: 'full_name', align: 'center' },
          { text: 'First Name', value: 'first_name', align: 'center' },
          { text: 'Last Name', value: 'last_name', align: 'center' },
          { text: 'City', value: 'city', align: 'center' },
          { text: 'Zip', value: 'zip', align: 'center' },
          { text: 'Year', value: 'year', align: 'center' },
          { text: 'Make', value: 'make', align: 'center' },
          { text: 'Model', value: 'model', align: 'center' },
          { text: 'Trim', value: 'trim', align: 'center' },
          { text: 'New/Used', value: 'used', align: 'center' },
          { text: 'Price', value: 'sales_price', align: 'center' },
          { text: 'Cost', value: 'cost', align: 'center' },
          { text: 'VIN', value: 'vin', align: 'center' },
          { text: 'Stock Number', value: 'stock_number', align: 'center' },
          { text: 'Mileage', value: 'mileage', align: 'center' },
          { text: 'Sales Person', value: 'sales_person', align: 'center' },
          { text: 'Address 1', value: 'address_1', align: 'center' },
          { text: 'Address 2', value: 'address_2', align: 'center' },
          { text: 'Phone', value: 'phone', align: 'center' },
          { text: 'Email', value: 'email', align: 'center' },
          { text: 'APR', value: 'apr', align: 'center' },
          { text: 'Term', value: 'bank_term', align: 'center' },
          { text: 'Monthly Payment', value: 'monthly_payment', align: 'center' },
          { text: 'Bank', value: 'bank_id', align: 'center' },
          { text: 'Down Payment', value: 'down_payment', align: 'center' },
          { text: 'Term Date', value: 'term_date', align: 'center' },
          { text: 'Payments Remaining', value: 'months_remaining', align: 'center' },
          { text: 'Term Complete %', value: 'term_complete_percent', align: 'center' },
          { text: 'Address Validated?', value: 'validated', align: 'center' },
          { text: 'Sale Type', value: 'sale_type', align: 'center' },
          { text: 'Deal Type', value: 'deal_type', align: 'center' },
          { text: 'Opt Out', value: 'opt_out', align: 'center' },
        ],
        templateRules: [
          {template: '{customer.first_name}', definition: 'First name of the customer.'},
          {template: '{customer.last_name}', definition: 'Last name of the customer.'},
          {template: '{year}', definition: 'The vehicle year.'},
          {template: '{make}', definition: 'The vehicle make.'},
          {template: '{model}', definition: 'The vehicle model.'},
          {template: '{dealer.name}', definition: 'Name of your dealership.'},
          {template: '{user.first_name}', definition: 'Your first name.'},
          {template: '{user.last_name}', definition: 'Your last name.'},
          {template: '{user.name}', definition: 'Your full name.'},
          {template: '{user.email}', definition: 'Your email address.'},
        ],
        startLastServicedDate: new Date(new Date().setDate(new Date().getDate() - 7))
          .toISOString()
          .substring(0, 10),
        endLastServicedDate: new Date().toISOString().substring(0, 10),
        startLastServicedDateMenu: false,
        endLastServicedDateMenu: false,
        opCodes: [],
        opCodeSearch: null,
        entries: [],
        isLoading: false,        
      }
    },
    computed: {
      formattedCampaignDate () {
        return this.formatDate(this.campaignDate)
      },
      formattedStartDate () {
        return this.formatDate(this.startDate)
      },
      formattedEndDate () {
        return this.formatDate(this.endDate)
      },
      formattedLastServiceStartDate () {
        return this.formatDate(this.startLastServicedDate)
      },
      formattedLastServiceEndDate () {
        return this.formatDate(this.endLastServicedDate)
      },
      ...mapGetters(['accountId', 'accountName', 'user']),
    },
    watch: {
      accountId () {
        this.getMakes()
        this.getModels()
        this.getYears()
        this.getStates()
        this.getUsers()
      },
      useAllDatesSwitch () {
        this.updateDefaultDates()
      },
      missionType() {
        if (this.missionType == 'Service') {
          this.useAllDatesSwitch = false
          this.selectedHeaders = [
            { text: 'RO #', value: 'ro_number' },
            { text: 'Customer Number', value: 'customer_number' },
            { text: 'First Name', value: 'first_name', align: 'center' },
            { text: 'Last Name', value: 'last_name', align: 'center' },
            { text: 'Address 1', value: 'address_1', align: 'center' },
            { text: 'Address 2', value: 'address_2', align: 'center' },
            { text: 'City', value: 'city', align: 'center' },
            { text: 'State', value: 'state', align: 'center' },
            { text: 'Zip', value: 'zip', align: 'center' },
            { text: 'Phone', value: 'phone', align: 'center' },
            { text: 'Email', value: 'email', align: 'center' },
            { text: 'Year', value: 'year', align: 'center' },
            { text: 'Make', value: 'make', align: 'center' },
            { text: 'Model', value: 'model', align: 'center' },
            { text: 'VIN', value: 'vin', align: 'center' },
            { text: 'Closed Date', value: 'close_date', align: 'center' },
            { text: 'Customer Total Sale', value: 'customer_total_sale', align: 'center' },
            { text: 'Opt Out', value: 'opt_out', align: 'center' },
          ]
          this.getModels()
        }

        if (this.missionType == 'Sales') {
          this.useAllDatesSwitch = true
          this.selectedHeaders = [
            { text: 'Customer Number', value: 'customer_number', align: 'center' },
            { text: 'Deal Number', value: 'deal_number' },
            { text: 'Sold Date', value: 'sold_at' },
            { text: 'Name', value: 'full_name', align: 'center' },
            { text: 'First Name', value: 'first_name', align: 'center' },
            { text: 'Last Name', value: 'last_name', align: 'center' },
            { text: 'City', value: 'city', align: 'center' },
            { text: 'Zip', value: 'zip', align: 'center' },
            { text: 'Year', value: 'year', align: 'center' },
            { text: 'Make', value: 'make', align: 'center' },
            { text: 'Model', value: 'model', align: 'center' },
            { text: 'Trim', value: 'trim', align: 'center' },
            { text: 'New/Used', value: 'used', align: 'center' },
            { text: 'Price', value: 'sales_price', align: 'center' },
            { text: 'Cost', value: 'cost', align: 'center' },
            { text: 'VIN', value: 'vin', align: 'center' },
            { text: 'Stock Number', value: 'stock_number', align: 'center' },
            { text: 'Mileage', value: 'mileage', align: 'center' },
            { text: 'Sales Person', value: 'sales_person', align: 'center' },
            { text: 'Address 1', value: 'address_1', align: 'center' },
            { text: 'Address 2', value: 'address_2', align: 'center' },
            { text: 'Phone', value: 'phone', align: 'center' },
            { text: 'Email', value: 'email', align: 'center' },
            { text: 'APR', value: 'apr', align: 'center' },
            { text: 'Term', value: 'bank_term', align: 'center' },
            { text: 'Monthly Payment', value: 'monthly_payment', align: 'center' },
            { text: 'Bank', value: 'bank_id', align: 'center' },
            { text: 'Down Payment', value: 'down_payment', align: 'center' },
            { text: 'Term Date', value: 'term_date', align: 'center' },
            { text: 'Payments Remaining', value: 'months_remaining', align: 'center' },
            { text: 'Term Complete %', value: 'term_complete_percent', align: 'center' },
            { text: 'Address Validated?', value: 'validated', align: 'center' },
            { text: 'Sale Type', value: 'sale_type', align: 'center' },
            { text: 'Deal Type', value: 'deal_type', align: 'center' },
            { text: 'Opt Out', value: 'opt_out', align: 'center' },
          ]
        }
        this.updateDefaultDates()
      },
      opCodeSearch (val) {
        this.loading = true

        var data = {term: this.opCodeSearch}
        DmsReportingService.searchOpCodes(data)
          .then(response => {
            this.entries = this.entries.concat(response.data)
            this.entries  = this.entries.filter((v, i, a) => a.indexOf(v) === i);
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
          .finally(() => (this.loading = false))
      },      
      filterByAprSwitch () {
        if (this.loadingCampaign) { return }
        this.showAprDialog = this.filterByAprSwitch
      },
      filterByPayment () {
        if (this.loadingCampaign) { return }
        this.showPaymentDialog = this.filterByPayment
      },
      filterByPaymentsRemaining () {
        if (this.loadingCampaign) { return }
        this.showPaymentsRemainingDialog = this.filterByPaymentsRemaining
      },
      filterByMonthsSinceSale () {
        if (this.loadingCampaign) { return }
        this.showMonthsSinceSaleDialog = this.filterByMonthsSinceSale
      },
      filterByTermPercentComplete () {
        if (this.loadingCampaign) { return }
        this.showTermPercentDialog = this.filterByTermPercentComplete
      },
    },
    created () {
      this.loadPhoneSettings()
      this.getMakes()
      this.getModels()
      this.getYears()
      this.getStates()
      this.getUsers()
      this.loadCampaign()
    },
    methods: {
      showMessageHelpDialog: function () {
        this.showMessageHelp = true
      },
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },
      updateDefaultDates: function () {
        if (this.loadingCampaign) { return }
        if (this.missionType == 'Sales') {
          if (this.useAllDatesSwitch) {
            this.startDate = null
            this.endDate = null
          } else {
            this.startDate = this.defaultSalesStartDate()
            this.endDate = this.defaultSalesEndDate()
          }
        }
        if (this.missionType == 'Service') {
          if (this.useAllDatesSwitch) {
            this.startDate = null
            this.endDate = null
          } else {
            this.startDate = this.defaultServiceStartDate()
            this.endDate = this.defaultServiceEndDate()
          }
        }
      },
      handleStepOneClick: function () {
        if (this.missionType === 'Sales') {
          this.loadStep(2);
        }
        if (this.missionType === 'Service') {
          this.loadStep(3);
        }
      },
      handleStepThreeBack: function () {
        if (this.missionType === 'Sales') {
          this.loadStep(2);
        }
        if (this.missionType === 'Service') {
          this.loadStep(1);          
        }
      }, 
      loadStep: function (stepNumber) {
        this.wizard = stepNumber
        this.$router.replace({name: 'New Mission', query: { step: stepNumber } }).catch(()=>{});
        // Appcues.page();

      },  
      loadCampaign: function () {
        this.campaignId = (this.$route.params.campaign_id)
        if (typeof this.campaignId === 'undefined') {
          return
        }
        this.loading = true
        this.loadingMessage = 'Loading Mission'
        ChatovateService.getChatovateCampaign(this.campaignId)
          .then(response => {
            this.loadingCampaign = true
            this.campaignName = response.data.name
            this.initialTextMessage = response.data.initial_text_message
            this.secondTextMessage = response.data.second_text_message
            this.thirdTextMessage = response.data.third_text_message
            this.daysToWaitForSecondText = response.data.days_to_wait_for_second_text
            this.daysToWaitForThirdText = response.data.days_to_wait_for_third_text
            this.maxNewLeadsPerDay = response.data.max_new_leads_per_day
            this.missionType = response.data.mission_type

            if (response.data.params.apr) {
              this.filterByAprSwitch = true
              this.aprFilterOperatorValue = this.filterOperators.findIndex(e => e.operator === response.data.params.apr_operator)
              this.aprFilterValue = response.data.params.apr
            }
            if (response.data.params.payment) {
              this.filterByPayment = true
              this.paymentFilterOperatorValue = this.filterOperators.findIndex(e => e.operator === response.data.params.payment_operator)
              this.paymentFilterValue = response.data.params.payment
            }
            if (response.data.params.months_remaining) {
              this.filterByPaymentsRemaining = true
              this.paymentRemainingFilterOperatorValue = this.filterOperators.findIndex(e => e.operator === response.data.params.months_remaining_operator)
              this.paymentsRemainingFilterValue = response.data.params.months_remaining
            }
            if (response.data.params.months_since_sale) {
              this.filterByMonthsSinceSale = true
              this.monthsSinceSaleFilterOperatorValue = this.filterOperators.findIndex(e => e.operator === response.data.params.months_since_sale_operator)
              this.monthsSinceSaleFilterValue = response.data.params.months_since_sale
            }
            if (response.data.params.term_complete_percent) {
              this.filterByTermPercentComplete = true
              this.termPercentFilterOperatorValue = this.filterOperators.findIndex(e => e.operator === response.data.params.term_complete_percent_operator)
              this.termPercentFilterValue = response.data.params.term_complete_percent
            }
            if (response.data.params.year) {
              this.filterByYearsSwitch = true
              this.selectedYears = response.data.params.year.split(',')
            }
            if (response.data.params.make) {
              this.filterByMakesSwitch = true
              this.selectedMakes = response.data.params.make.split(',')
            }
            if (response.data.params.model) {
              this.filterByModelsSwitch = true
              this.selectedModels = response.data.params.model.split(',')
            }
            if (response.data.params.states) {
              this.filterByStatesSwitch = true
              this.selectedStates = response.data.params.states.split(',')
            }
            if (response.data.params.start_date) {
              this.useAllDatesSwitch = false
              this.startDate = response.data.params.start_date
            }
            if (response.data.params.end_date) {
              this.useAllDatesSwitch = false
              this.endDate = response.data.params.end_date
            }

            if (response.data.params.start_last_serviced_date) {
              this.filterByLastServicedSwitch = true
              this.startLastServicedDate = response.data.params.start_last_serviced_date
            }
            if (response.data.params.end_last_serviced_date) {
              this.filterByLastServicedSwitch = true
              this.endLastServicedDate = response.data.params.end_last_serviced_date
            }

            if (response.data.params.op_codes) {
              this.filterByOpCodesSwitch = true
              this.entries = response.data.params.op_codes.split(',')
              this.opCodes = response.data.params.op_codes.split(',')
            }

            this.selectedUsers = response.data.user_uuids
            this.loading = false
          })
          .then(response => {
            this.loadingCampaign = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      saveCampaign: function () {
        if (this.wizard !== 4) {
          return
        }
        this.loading = true
        if (this.campaignId) {
          this.loadingMessage = 'Updating Mission'
        } else {
          this.loadingMessage = 'Saving Mission'
        }
        var data = {
          startDate: this.startDate,
          endDate: this.endDate,
        }
        data.campaignId = this.campaignId
        // Build up the params to call getSales with.
        if (this.filterByAprSwitch) {
          data.aprOperator = this.filterOperators[this.aprFilterOperatorValue].operator
          data.apr = this.aprFilterValue
        }

        if (this.filterByPayment) {
          data.paymentOperator = this.filterOperators[this.paymentFilterOperatorValue].operator
          data.payment = this.paymentFilterValue
        }

        if (this.filterByPaymentsRemaining) {
          data.paymentsRemainingOperator = this.filterOperators[this.paymentRemainingFilterOperatorValue].operator
          data.paymentsRemaining = this.paymentsRemainingFilterValue
        }

        if (this.filterByMonthsSinceSale) {
          data.monthsSinceSaleOperator = this.filterOperators[this.monthsSinceSaleFilterOperatorValue].operator
          data.monthsSinceSale = this.monthsSinceSaleFilterValue
        }

        if (this.filterByTermPercentComplete) {
          data.termCompletePercentOperator = this.filterOperators[this.termPercentFilterOperatorValue].operator
          data.termCompletePercent = this.termPercentFilterValue
        }

        if (this.filterByMakesSwitch) {
          data.makes = this.selectedMakes.join(',')
        }

        if (this.filterByModelsSwitch) {
          data.models = this.selectedModels.join(',')
        }

        if (this.filterByYearsSwitch) {
          data.years = this.selectedYears.join(',')
        }

        if (this.filterByStatesSwitch) {
          data.states = this.selectedStates.join(',')
        }

        if (this.filterByLastServicedSwitch) {
          data.startLastServicedDate = this.startLastServicedDate
          data.endLastServiceDate = this.endLastServicedDate
        }
        if (this.filterByOpCodesSwitch) {
          data.opCodes = this.opCodes.join(',')
        }

        data.headerText = this.selectedHeaders.map(h => encodeURIComponent(h.text))
        data.headerValues = this.selectedHeaders.map(h => encodeURIComponent(h.value))

        data.initialTextMessage = this.initialTextMessage
        data.secondTextMessage = this.secondTextMessage
        data.thirdTextMessage = this.thirdTextMessage
        data.daysToWaitForSecondText = this.daysToWaitForSecondText
        data.daysToWaitForThirdText = this.daysToWaitForThirdText
        data.name = this.campaignName
        data.maxNewLeadsPerDay = this.maxNewLeadsPerDay
        data.missionType = this.missionType
        data.users = this.selectedUsers.map(user => user.id)
        if (this.campaignId) {
          ChatovateService.updateChatovateCampaign(data)
            .then(response => {
              this.loadingMessage = 'Updating Mission'
              this.loading = false
            })
            .then(response => {
              this.$router.push({ name: 'Missions' })
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        } else {
            this.loadingMessage = 'Saving Mission'
            ChatovateService.createChatovateCampaign(data)
            .then(response => {
              this.loading = false
              // Appcues.identify(this.user.uuid, { launchedMission: 1});              
            })
            .then(response => {
              this.$router.push({ name: 'Missions' })
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
      },
      navigateToSettings: function() {
        this.$router.push({ name: 'Settings' })
      },
      loadPhoneSettings: function () {
        this.loadingMessage = 'Loading Settings'
        this.loading = true
        ChatovateService.getChatovatePhoneSetting()
          .then(response => {
            this.callTrackingNumber = response.data.call_tracking_number
            this.serviceCallTrackingNumber = response.data.service_call_tracking_number
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      getMakes: function () {
        this.loading = true
        DmsService.getSalesMakes()
          .then(response => {
            if (response.data.makes) {
              this.allMakes = response.data.makes
            } else {
              this.allMakes = []
            }
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      getModels: function () {
        if (this.missionType === 'Sales') {
          this.loading = true
          this.loadingMessage = 'Loading Sales Models'
          DmsService.getSalesModels()
            .then(response => {
              if (response.data.models) {
                this.allModels = response.data.models
              } else {
                this.allModels = []
              }
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }
        if (this.missionType === 'Service') {
          this.loading = true
          this.loadingMessage = 'Loading Service Models'          
          DmsService.getServiceModels()
            .then(response => {
              if (response.data.models) {
                this.allModels = response.data.models
              } else {
                this.allModels = []
              }
              this.loading = false
            })
            .catch(error => {
              this.loading = false
              this.error = NetworkService.getErrorMessage(error)
              this.showErrorDialog = true
            })
        }        
      },
      getYears: function () {
        this.loading = true
        DmsService.getSalesYears()
          .then(response => {
            if (response.data.years) {
              this.allYears = response.data.years
            } else {
              this.allYears = []
            }
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      getStates: function () {
        this.loading = true
        DmsService.getSalesStates()
          .then(response => {
            if (response.data.states) {
              this.allStates = response.data.states
            } else {
              this.allStates = []
            }
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      getUsers: function () {
        this.loading = true
        UserService.getUsersByPermission('chatovate')
          .then(response => {
            if (response.data) {
              this.users = response.data
              if (this.selectedUsers.length === 0) {
                this.selectedUsers = this.users.filter(user => user.active_user)
              }
            } else {
              this.allStates = []
            }
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
      defaultSalesStartDate () {
        return new Date(new Date().setDate(new Date().getDate() - 360)).toISOString().substring(0, 10)
      },
      defaultSalesEndDate () {
        return new Date().toISOString().substring(0, 10)
      },
      defaultServiceStartDate () {
        return new Date(new Date().setDate(new Date().getDate() - 90)).toISOString().substring(0, 10)
      },
      defaultServiceEndDate () {
        return new Date().toISOString().substring(0, 10)
      },
      formatFilterMessage (switchName, switchObject, filterOperator, filterValue) {
        if (switchObject) {
          const message = `${this.filterOperators[filterOperator].text} ${filterValue}`
          return `Filter by ${switchName}: ${switchObject ? message : 'No'}`
        } else {
          return `Filter by ${switchName}: No`
        }
      },
    },
  }
</script>
